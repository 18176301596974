import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import styled, { css } from "styled-components";
import Img from "gatsby-image";
import logoImg from "../images/logo-icon.png";
import { LogoImg } from "../components/Logo";
import AmazonDetailListing from "../components/AmazonListing";
import DoddsHeader from "../components/DoddsHeader";

import {
  Layout,
  Article,
  Wrapper,
  Button,
  SectionTitle,
  Subline
} from "../components";
import { StyledH3, SecondaryH2 } from "../components/StyledElements";

const Content = styled.div`
  grid-column: 2;
  text-align: center;
  ${"" /* box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1); */}
  border-radius: 1rem;
  padding: 3rem 6rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  overflow: hidden;
`;

const Hero = styled.div`
  grid-column: 2;
  padding: 3rem 2rem 6rem 2rem;
  text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  color: ${props => props.theme.colors.white};

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1rem 4rem 1rem;
  }

  p {
    font-size: 1.68rem;
    margin-top: -1rem;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 1.45rem;
    }
    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      font-size: 1.25rem;
    }
  }
`;

const IndexPage = ({}) => (
  <Layout>
    <Wrapper>
      <React.Fragment>
        <div
          css={css`
            grid-area: header;
          `}
        >
          <DoddsHeader />
        </div>
        <Hero>
          {/* <LogoImg src={logoImg} alt={"Fantasy Name Machine logo"} /> */}
          {/* <SecondaryH2>Fun with Machine Learning</SecondaryH2> */}
        </Hero>
        <Content>
          <SectionTitle>
            Pen and Paper Resources
            <Subline>for GM's and Players alike</Subline>
          </SectionTitle>
          <AmazonDetailListing
            ASIN={`1793083207`}
            description={
              <p>{`The book that started it all - contains names of people places and things with brief descriptions. Also contains room for your own GM ideas, notes, and maps.`}</p>
            }
            title={`Campaign Companion`}
          />
          <AmazonDetailListing
            ASIN={`1081001909`}
            description={
              <p>{`Contains 3 unique sets of items with backstories as well as 100 unique magical items you might find in your fantasy setting. Also contains room for your own GM ideas, notes, and maps.`}</p>
            }
            title={`Campaign Companion: Magical Artifacts, Wondrous and Cursed`}
          />
        </Content>
      </React.Fragment>
    </Wrapper>
  </Layout>
);

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    })
  }).isRequired
};

// export const IndexQuery = graphql`
//   query resourcesQuery {
//     allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt(pruneLength: 200)
//         timeToRead
//         fields {
//           slug
//         }
//         frontmatter {
//           title
//           date(formatString: "MM/DD/YYYY")
//           categories
//           primaryColor
//           banner {
//             ...bannerImage640
//           }
//         }
//       }
//     }
//   }
// `;
